@import 'src/styles/variables';

.ds-order-summary-card {
    background: $white;
    background-size: cover;
    border-radius: 4px;
    min-width: 284px !important;

    @media (min-width: $widescreen) {
        margin-left: 6px;
        min-height: 257px !important;
    }

    @media (max-width: $desktop) {
        margin-left: 4px;
    }

    .gray-border-bottom {
        border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    }

    .floating-bottom-bar {
        background-color: rgb(250, 253, 255);
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
        bottom: 0;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 999;
    }

    .coupon-area {
        padding: 20px 0;
    }

    .pay-button {
        width: 247px;
        height: 50px;
        margin-left: 6px;
        border: none;
        border-radius: 4px;
        list-style: 24px;
        font-size: $medium;
        cursor: pointer;
        
        @media (max-width: $phone) {
            border-radius: 10px;
            font-size: 16px;
        }
    }

    .summary {
        display: flex;
        height: 70px;
        justify-content: space-between;

        &:nth-child(1) {
            height: 62px;
        }

        @media (max-width: $desktop) {
            height: 54px;
        }
    }
    
    .mt-text {
        margin-top: 35px;
    }

    .contents {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;
    }

    .coupon-input {
        margin: 0 20px; 
    }

    .ruler {
        border: 1px solid $primaryborder;
        margin-left: -20px;
        margin-right: -20px;
    }
}
