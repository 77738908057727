@import 'styles/variables';
@import 'styles/_mixins.scss';

#ds-pagination {
    .mdc-notched-outline__leading {
        border-width: 1px !important;
    }

    .mdc-notched-outline__trailing {
        border-width: 1px !important;
    }

    .mdc-text-field {
        background-color: $transparent;
    }

    .mdc-text-field--focused {
        background: $lightergray;
        outline: 1px solid $purple-text;
        
        .mdc-text-field__input {
            color: $black;    
            
            &::placeholder {
                color: $transparent;
            }
        }
    }

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
        border: none !important;
    }

    .mdc-text-field__input {
        font-size: $regular;
        letter-spacing: 0.68px;
        color: $midgray;
        caret-color: $midgray;

        &::placeholder {
            padding: 0 3px;
        }
    }

    .pagination {
        justify-content: center;
    }

    .pagination a {
        background-color: $lightergray;
        color: $midgray;
        margin: 0 4px;
        @include square(30px);
    }

    .navigation-arrow {
        border: 1px solid $purple-text;
        border-radius: 6px;

        &:hover {
            border: 1px solid $purplehover;
        }

        &:active {
            border: 1px solid $purple-text;
        }
    }

    .page-number:not(.page-number.highlighted),
    .mdc-text-field:not(.mdc-text-field--focused) {
        &:hover {
            border-radius: 6px;
            outline: 1px solid $purplehover;
        }

        &:active {
            border-radius: 6px;
            outline: 1px solid $purple-text;
        }
    }

    .page-number {
        border: none;
    }

    .page-link{
        transition: none;

        &:focus {
            box-shadow: none;
        }
    }

    .highlighted {
        background: $sign-up-blue !important;
        border-radius: 6px;
        color: $white !important;

        &.page-dots {
            @include square(20px !important);
        }
    }

    .input-pagination {
        @include square(30px);
        padding: 0 6px;
        border-radius: 6px;
    }

    .highlighted.page-dots,
    .pagination a.page-dots {
        background: $darkergray-medium;
        border-radius: 50%;
        @include square(15px);
        margin-left: 33px;
    }

    .pagination a.page-dots:not(.highlighted) {
        transform: translateY(3px);
    }
}
