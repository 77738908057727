@import 'styles/variables';

#ds-gallery-dialog {
    .close-icon {
        position: absolute;
        right: 20px;
        top: 20px;

        @media (max-width: $phone) {
            right: 10px;
            top: 10px;
        }
    }

    .mdc-dialog__surface {
        min-width: 582px;
        height: 500px;
        border-radius: 0;

        & .mdc-dialog__header+.mdc-dialog__content {
            padding: 38px 42px 38px 48px;
        }

        & .carousel-inner {
            margin: 0 12px 0 7px;
            width: 424px;
            height: 424px;

            & .carousel-item.active {
                margin: 0;

                & img {
                    object-fit: scale-down;
                }
            }
        }

        &:hover {
            & .carousel-control {
                & span {
                    display: block;
                }
            }
        }

        @media (max-width: $phone) {
            min-width: 345px;
            height: 345px;

            & .mdc-dialog__header+.mdc-dialog__content {
                padding: 27px 29px 27px 33px;
            }

            & .carousel-inner {
                width: 250px;
                height: 268px;
            }

            & .carousel-control {
                & span {
                    display: block;
                }
            }
        }
    }

    .carousel-control {
        min-width: 25px;

        & span {
            display: none;
        }
    }
}
