@import 'styles/variables';

#ds-game-banner {
    p {
        font-size: $primaryfont;
        color: $ds-home;
    }

    .banner-title {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .admin-view-position {
        top: 100%;
    }
}
