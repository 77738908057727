@import 'styles/variables';

#ds-line {
    .purple-line {
        background: $lightpurple;
        border-radius: 4px;
    }

    .line {
        width: 60px;
        transition: 0.3s;
        margin-top: 16px;
        margin-bottom: 30px;
    }

    @media (max-width: $desktop) {
        margin-bottom: 20px !important;
    }

    .green-line {
        height: 4px;
        margin-top: 0;
        background: $second-success-green;
        border-radius: 4px;
    }

    .blue-line {
        height: 4px;
        margin-top: 0;
        background: $sign-up-blue;
        border-radius: 4px;
    }
}
