@import 'styles/variables';
@import 'styles/typography';

#ds-faq-dialog {
    .close-icon {
        color: $darkergray;
        margin-top: 25px;
        margin-right: 30px;

        @media (max-width: $phone) {
            margin-top: 15px;
            margin-right: 15px;
        }
    }

    .mdc-data-table {
        border: none;
    }

    .mdc-dialog__surface {
        min-width: 906px !important;
        min-height: 516px !important;

        @media (max-width: $tablet) {
            min-width: 540px !important;
        }

        @media (max-width: $phone) {
            min-width: 335px !important;
            min-height: 350px !important;
        }
    }

    .main-title p {
        text-align: center !important;
    }

    .table-container {
        padding-left: 88px;
        padding-right: 88px;
        margin-top: 31px;

        @media (max-width: $tablet) {
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        @media (max-width: $phone) {
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 0;
            margin-bottom: 15px;
        }
    }

    .table {
        margin: 0;

        & th {
            color: $white;
            text-align: center;
        }

        & > :not(:first-child) {
            border-top: none;
        }
    }

    .row-subtitles.mdc-data-table__row {
        height: 46px;
        vertical-align: middle;

        & .mdc-data-table__cell {
            padding-left: 16px;
            white-space: normal;

            @media (max-width: $phone) {
                padding-left: 8px;
                line-height: 14px;
            }
        }
    }

    .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        background-color: $transparent;
    }

    .table-bordered {
        border: 1px solid $ds-purple;
    }

    .table-content {
        overflow: hidden;
        white-space: normal;
        padding-top: 27px;
    }

    #page-content-area p {
        padding: 0;
        margin: 0;
    }

    .mdc-data-table__header-cell {
        padding: 0;
        border-width: 0;

        &:last-child,
        &:first-child {
            border-radius: 0;
        }

        @media (max-width: $phone) {
            #page-content-area p {
                font-size: $medium;
            }
        }
    }

    .mdc-data-table__header-row {
        height: 40px;
        vertical-align: middle;
        background: linear-gradient(90.12deg, rgba(67, 0, 169, 1) 2.45%, rgba(0, 51, 140, 1) 101.9%);

        @media (max-width: $phone) {
            height: 30px;
        }
    }

    .table-content-cell {
        padding-top: 27px;
        padding-right: 44px;
        padding-left: 0;

        @media (max-width: $tablet) {
            padding-top: 15px;
            padding-right: 8px;
        }

        @media (max-width: $phone) {
            padding-top: 15px;
            padding-right: 8px;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }

    ul {
        padding-left: 30px;

        li {
            font-size: $small;
            line-height: 20px;
            letter-spacing: 0.512px;
            margin-bottom: 19px;

            &::marker {
                color: $ds-purple;
            }
        }

        @media (max-width: $tablet) {
            li {
                margin-bottom: 9px;
            }
        }

        @media (max-width: $phone) {
            padding-left: 20px;
        }
    }
}
