@import 'styles/variables';

#ds-payment-method-selector {
    .mdc-text-field--outlined {
        height: 43px;
    }

    .mdc-text-field__icon--leading {
        margin-left: 8px;
    }

    .expanded-selected {
        color: $white;
        left: 11px;
        margin-top: 5px !important;
        right: 11px;
        width: auto !important;
        padding: 0;
        z-index: 5;
    }

    .balance-container {
        display: none;
        position: absolute;
        bottom: 8px;
        left: 55px;
    }

    .balance-container:hover {
        display: block;
    }

    .search-field {
        background-color: rgba(88, 101, 242, 0.6);
        margin-bottom: 15px;
    }

    .input-password.search-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        overflow: hidden;
        padding-right: 25px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $white;
    }

    .expand-icon {
        position: absolute;
        right: 5px;
        transform: rotate(0deg);
        transition: 0.3s ease;
    }

    .show {
        .expand-icon {
            transform: rotate(180deg);
            transition: 0.3s ease;
        }
    }

    .balance-text {
        cursor: pointer;
        margin-bottom: 5px;
    }

    .btn:focus {
        box-shadow: none;
    }
    
    .mdc-checkbox.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path {
        animation: none;
    }

    .cursor-pointer:hover {
        background-color: rgba(88, 101, 242, 0.6);
        transition: .5s;

        + .balance-container {
            display: block;
        }

        .container-balance-padding {
            min-height: 70px;
        }
    }

    .dropdown-menu {
        background-color: $palegray;
        z-index: 1000;
    }

    .cont-icon-payment {
        height: 56px;
        width: 26px;
        border-radius: 50%;
        background: transparent;
    }

    .close-icon {
        top: 26px;
        right: 28px;
        cursor: pointer;
    }

    .payment-method-image {
        max-width: 24px;
        max-height: 100%;
    }

    .black-filter {
        filter: brightness(0) saturate(100%);
    }

    .payment-method-img {
        display: inline-block;
        vertical-align: middle;

        img {
            height: auto;
            max-height: 100%;
            max-width: 24px;
            vertical-align: middle;
            width: auto;
        }
    }

    .payment-method-selector {
        max-height: 50px;
        height: 50px;
    }

    .payment-method-component {
        max-width: 324px;

        @media (max-width: $desktop) {
            margin-top: 20px;
            max-width: unset;
        }
        
        @media (max-width: $tablet) {
            margin-top: 0;
        }
    }

    .selected-method-balance {
        position: absolute;
        top: 6px;
        max-width: 200px;
    }

    .payment-icon {
        display: inline-block;
        
        @media (max-width: $phone) {
            width: 48px;
        }
    }

    .payment-method-balance {
        cursor: pointer;
        font-size: $tiny;
        top: 28px;
        left: 74px;
    }

    .custom-checkbox {   
        padding: 2px !important;
        width: 16px !important;
        height: 16px !important;
        top: 0;
        left: 0;

        .mdc-checkbox__native-control, .mdc-checkbox, .mdc-checkbox__background {
            padding: 2px !important;
            width: 16px !important;
            height: 16px !important;
            top: 0;
            left: 0;
        }
    }

    .payment-method-scrollbar {
        max-height: 472px;
    }

    .payment-method-scrollbar .simplebar-horizontal {
        display: none !important;
    }

    .simplebar-scrollbar:before {
        left: 0 !important;
        right: 5px !important;
    }

    @media (max-width: $desktop) {
        .payment-method-component {
            max-width: unset;
        }
    }

    .mdc-text-field__input::-webkit-input-placeholder { 
        color: $white; 
        font-size: $regular;
    }

    input {
        caret-color: $white;
    }
}
