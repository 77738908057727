@import 'styles/variables';

#ds-why-choose-card {
    background-color: $white;
    border-radius: 4px;
    max-width: 294px;
    height: 362px;
    padding: 20px;
    color: $ds-home;
    
    .text-large-plus {
        p {                
            font-family: $primaryfont;
            line-height: 1;
            margin-bottom: 30px !important;

            @media (max-width: $tablet) {
                margin-bottom: 20px !important;
            }

            @media (max-width: $phone) {
                margin-bottom: 10px !important;
            }
        }
    }

    @media (max-width: $widescreen) {
        height: 420px;
        border-radius: 0;
    }    

    @media (max-width: $tablet) {
        max-width: 263px;
        height: 336px;

        .text-large-plus {
            font-size: $medium !important;
        }
    }
    
    @media (max-width: $phone) {
        max-width: 100%;
        height: 277px;
    }

    > div {
        margin-bottom: 10px;

        @media (max-width: $phone) {
            margin-bottom: 20px;
        }
    }

    > h4 {
        margin-bottom: 30px;
        
        @media (max-width: $tablet) {
            margin-bottom: 15px;
        }
    }

    .box-color {
        background-color: $sign-up-blue-light;
    }
}
