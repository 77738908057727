@import 'styles/variables';

#ds-user-dropdown {
    font-size: $regular;

    .material-symbols-outlined {
        font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 48 !important
    }

    .font-arrow {
        max-width: 10px;
        max-height: 8px;
        margin-left: 5px;
    }

    .user-info:hover {

        @media (min-width: $desktop) {
            .inner-list {
                display: block;
                position: relative;
                font-size: $regular !important;
                width: 200px;
    
                @media (max-width: $tablet) {
                    width: 190px;
                }
    
                @media (max-width: $phone) {
                    width: 150px;
                }
            }

            .alt-customer-dropdown-icon-down {
                visibility: hidden;
                opacity: 0;
            }
        
            .alt-customer-dropdown-icon-up {
                visibility: visible;
                opacity: 1;
            }

            .user-name {
                color: $sign-up-blue !important;
            }
        }
    }

    .alt-customer-dropdown-icon-down,
    .alt-customer-dropdown-icon-up {
        width: 13px;
        line-height: 100%;
        vertical-align: middle;
        top: 10px;
        right: -20px;
        max-width: unset;
        transition: all .15s ease-in-out;
    }

    .alt-customer-dropdown-icon-up {
        visibility: hidden;
        opacity: 0;
    }

    .mask {
        background-color: rgba(67, 0, 169, 0.5);
        width: 29px;
        height: 29px;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .user-img:hover {
        & .mask {
            opacity: 1;
        }
    }

    .user-profile {
        width: 29px;
        height: 29px;
        border-radius: 100%;
        object-fit: cover;
    }

    .list {
        right: -12px;
        padding: 20px;
    }

    .inner-list {
        list-style: none;
        display: none;
        margin-top: 3px !important;
        background-color: $dropdownprimarybackground !important;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        z-index: 1000;

        @media (max-width: $desktop) {
            margin-left: -110px;
            margin-top: -3px !important;
        }
    }

    .list-item {
        transition: all 0.4s ease;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 16px;
        letter-spacing: 0.448px;

        &:not(:last-child) {
            border-bottom: solid 1px $divisor-gray-light;
        }

        & span {
            color: $white;

            &.material-icons-outlined {
                color: $white;
            }
        }

        &:hover {
            background-color: $dropdownwhite;
            cursor: pointer;

            & span {
                color: $white !important;
            }
        }

        @media (max-width: $phone) {
            font-size: 12px !important;
            padding-top: 14px;
            padding-bottom: 14px;
            line-height: 14px;
        }
    }

    .dropdown-label {
        @media (max-width: $desktop) {
            font-size: $small;
        }
    }

    .user-name {
        max-width: 95px;
        transition: all .2s ease-in-out;
        color: $white;
    }

    .switch {
        width: 22px;
        height: 16px;
    }

    .slider:before {
        height: 16px;
        width: 16px;
    }
}
