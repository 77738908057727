@import 'styles/variables';

.ds-faq-question {
    background-color: $white;
    color: $dropdownprimarybackground;
    border-radius: 3px;
    cursor: pointer;
    height: 144px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    transition: all .5s ease;
    position: relative;

    &.active {
        height: fit-content;
    }

    .icon {
        color: $black;
        transition: 0.3s ease;
        height: 24px !important;
    }

    .opened {
        transform: rotate(180deg);
    }

    .header-expandable {
        display: flex;
        justify-content: space-between;
        font-size: $homecardhuge;
    }

    .question {
        line-height: 29px;
        letter-spacing: 0.448px;
    }

    .answer {
        line-height: 28px;
        letter-spacing: 0.512px;
        margin-bottom: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $dropdownprimarybackground;
        font-family: $primaryfont;
        font-size: $medium;

        & div {
            float: right;
        }
    }

    .answer-close {
        max-height: 75px;
        transition: 0.2s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        line-height: normal;

        @media (max-width: $phone) {
            max-height: 83px;
        }
    }

    .answer-close:after {
        position: absolute;
        bottom: 0;  
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top,
           rgba(255,255,255, 1) 20%, 
           rgba(255,255,255, 0) 80%
        );
        pointer-events: none;
    }

    .answer-open {
        max-height: 600px;
        transition: 0.5s ease;

        &::after {
            display: none; 
        }
    }

    &.highlight-me {
        animation: highlight ease-in-out 1s 2 alternate;
    }

    @keyframes highlight {
        0% {
            background-color: $white;
        }
        100% {
            background-color: rgb(49, 15, 162, 0.2);
        }
    }

    .text-regular {
        color: $softblack;
    }
}
