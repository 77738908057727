@import 'styles/variables';

#ds-page-content-area {
    p:last-of-type {
        margin-bottom: 0 !important;
    }

    a {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .font-size-inherit {
        font-size: inherit !important;
    }

    .font-weight-inherit {
        font-weight: inherit !important;
    }

    .mb-inherit {
        margin-bottom: inherit !important;
    }
}
