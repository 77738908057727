@import 'styles/variables';

#card-solutions {
    background-color: $palegray;
    height: 195px;
    border-radius: 4px;
    padding: 27px 28px 27px 28px;

    @media (max-width: $desktop) {
        max-height: 183px;
    }

    @media (max-width: $phone) {
        padding-left: 18px;
        padding-right: 18px;
        max-height: 190px;
    }

    .card-icon {
        width: 67px;
        height: 67px;
        border-radius: 4px;
    }

    .text-card-solutions {
        margin-left: 20px;
        width: 100%;

        @media (max-width: $extrascreen) {
            width: 380px;
        }

        @media (max-width: $widescreen) {
            width: 100%;
        }
    }

    .title-card {

        & p {
            color: $ds-home;
            line-height: 30px;
            margin-bottom: 3px !important;

            @media (max-width: $phone) {
                font-size: $medium;
                line-height: 24px;
                margin-bottom: 9px;
            }
        }
    }

    .text-card  {
        max-width: 443px;
        color: $ds-home;
        line-height: 27px;
        opacity: 0.75;

        @media (max-width: $phone) {
            font-size: $regular;
            line-height: 21px;
        }
    }
}

#ds-quad-why-choose-cards {
    > div {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;

        @media (max-width: $widescreen) {
            grid-column-gap: 0;
        }
        
        @media (max-width: $desktop) {
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 20px;
            grid-column-gap: 20px;
        }
        
        @media (max-width: $phone) {
            grid-template-columns: auto;
            grid-row-gap: 16px;
        }
    }
}

