@import 'styles/variables';

#ds-status-tag {
    position: relative;
    text-transform: capitalize;
    font-weight: 500;
    font-size: $regular;

    .tag {
        background-color: $transparent;
        padding: 0;
        border-radius: 4px;
        text-align: left;
        height: 30px;
    }

    .tag-text {
        font-size: $regular;
    }

    .completed,
    .complete,
    .received,
    .activated,
    .active {
        color: $second-success-green;
    }

    .pending,
    .draft {
        color: $pending-title;
    }

    .rejected {
        color: $red;
    }

    .refunded {
        color: $refunded-title;
    }

    .refund-pending {
        color: $refund-pending-title;
    }

    .refund-requested,
    .partially-refunded {
        color: $brown !important;
    }

    .created {
        color: $sign-up-blue;
    }

    .closed {
        color: $ds-home;
    }

    .pending.order {
        color: $pending-title;
    }

    @media (max-width: $tablet) {
        font-size: $small;

        .tag {
            padding: 5px 6px;
        }
    }

    @media (max-width: $phone) {
        font-size: $tiny;

        .tag {
            padding: 4px 6px;
            width: 100px;
        }
    }
}

#ds-verification-status-tag {
    .tag {
        height: 36px;
        padding: 4px;
        border-radius: 4px;
        font-size: $small;
    }

    .verified {
        background-color: $off-green;
        border: 1px solid rgba(0, 174, 103, 0.2);
        color: $darkergray;
    }

    .pending {
        background-color: rgba(87, 98, 213, 0.1);
        border: 1px solid $offlilac;
        color: $darkergray;
    }
}
