@import 'styles/variables';
@import 'styles/mixins';

#ds-icon {
    @include flex-center();

    .no-image-container {
        margin-top: 5px;
    }
}
