@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'styles/variables';

#ds-phone-input {
    
    .iti--allow-dropdown .iti__flag-container,
    .iti--separate-dial-code .iti__flag-container {
        right: auto;
        left: auto;
    }

    .iti.iti--allow-dropdown.iti--separate-dial-code {
        width: 100%;
    }

    .iti--separate-dial-code .iti__selected-flag {
        background-color: transparent;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }

    .iti input,
    #ds-phone-input .iti input[type="text"],
    #ds-phone-input .iti input[type="tel"] {
        padding-right: 36px;
    }

    #code > input {
        padding-right: 22px;
    }

    .confirm-button {
        position: absolute;
        right: 20px;
        top: 7px;
    }

    .button-blue-border {
        border: 1px solid $blue;
        height: 50px !important;
    }

    .mini-spinner-icon {
        position: absolute;
        top: 12px;
        right: 1.3rem;
    }

    .circle-icon {
        height: 16px;
        position: absolute;
        right: 1.6rem;
        top: 1rem;
        width: 16px;
    }

    .x-mark {
        position: absolute;
        right: 1.75rem;
        top: 1.25rem;
        height: 9px;
        width: 9px;
        filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(342deg) brightness(95%) contrast(108%);
    }

    .btn {
        background: linear-gradient(90.12deg, #4300a9 2.45%, #00338c 101.9%);
        border-radius: 6px;
    }

    .text-regular {
        color: $ds-home;
    }

    .text-purple {
        color: $sign-up-blue;
    }

    .font-weight-bold {
        font-weight: bold;
    }

    .text-small {
        @media (max-width: $tablet) {
            font-size: $tiny;
        }
    }
}

.iti__selected-flag {
    background-color: unset !important;
}

.iti__selected-flag:focus-visible {
    outline: unset !important;
}

.iti-mobile .iti--container, .iti--container {
    position: absolute;
    left: 0 !important;
    top: 55px !important;

    height: 200px;

    .iti__country-list {
        top: 0;
    }
}

.iti__country-list,
.iti--container {
    overflow-x: hidden;
    border: 0;
    border-radius: 4px;
    width: 100%;
    min-height: 200px;
}

.iti__flag-container {
    background-color: unset;
}
