@import 'styles/variables';

#ds-validator-progress {
    .validator {
        max-width: 458px;
        text-align: start;
        color: rgb(0, 0, 0);

        @media (max-width: ($desktop - 1)) {
            color: $white;

        }

        @media (max-width: ($phone)) {
            margin-right: 20px;
        }
    }

    .progress {
        height: 4px;
        width: 100%;
        border-radius: 3px;
        background-color: transparent;
    }

    .progress-bar-striped {
        background-image: none;
        border-radius: 3px;
        width: 0%;
    }

    .red {
        color: rgba(208, 8, 16, 1);
    }

    .green {
        color: $green;
    }

    .background-red {
        background-color: $red;
    }

    .background-yellow {
        background-color: $yellow;
    }

    .background-green {
        background-color: $green;
    }
}
