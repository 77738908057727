@use 'styles/variables' as v;
@use 'styles/mixins' as m;
@use '@chicksgroup/styles/variables' as v-global;
@use '@chicksgroup/styles/mixins' as m-global;
@use '@chicksgroup/styles/colors';

#ds-try-again-box {
    background-color: var(--cg-grey-100);
    @include m-global.flex-column($align: center);
    @include m.regular-container-paddings(1px);

    .unknown-container--divider {
        @include m-global.size(100%, 1.5px);
        margin-block: 20px;
        opacity: 1;
        background-color: var(--cg-grey-400);
        display: block;
        border:none;
    }

    button {
        border-radius: 4px !important;
    }

    p {
        @include m.no-spacing(true);
    }
    
    .ds-try-again-box__container {
        @include m.container-fluid(100%);
    }

    .content a {
        @include m-global.font(v-global.$medium, 500, $spacing: 0.04px, $color: var(--ds-secondary-700) !important);
    }

    .box {
        @include m.container-fluid(1136px);

        padding: v-global.$hugePad;
        border-radius: 4px;

        @media (width <= calc(v-global.$tablet + 1px)) {
            box-sizing: border-box;
            padding: v-global.$bigPad v-global.$hugePad;
        }
    }

    .header-container {
        --cds-icon-color: var(--cg-grey-500);
        --md-icon-size: 48px;
        gap: 12px;
        align-items: center;
    }

    .expired {
        @include m-global.font(v-global.$huge, 700, 36px, normal, var(--cg-grey-900) !important);
        
        h1, h2, h3, h4, p {
            word-break: break-word;
            margin: 0;
            color: var(--cg-grey-900);
        }

        @media (width <= v-global.$phone) {
            @include m-global.font($size: 24px, $height: 28px);
        }
    }
    
    .content {
            @include m-global.font(v-global.$medium, 400, $spacing: 0.014px, $color: var(--cg-grey-900));
            font-style: normal;
            word-break: break-word;
            margin-bottom: v-global.$bigPad;
        
        p, span, h1, h2, h3, h4 {
            @include m-global.font($spacing: 0.24px, $height: 32px);

            @media (width <= calc(v.$tablet + 1px)) {
                @include m-global.font(v-global.$regular !important, $spacing: 0.014px, $height: 24px);
            }
        }
    }

    .btn-content,
    .btn-try {
        @include m.container-fluid(248px);
        height: 52px;
    }

    .btn-try {
        @include m-global.font(v-global.$medium, 500, 20px, 0.04px, var(--cg-grey-100) !important);
        height: 52px;
    }
    
    p {
        font-family: v.$primaryfont;
    }

    @include m.media(v.$tablet) {
        background: transparent;
        background-color: v-global.$white;

        .box {
            padding: 40px 80px;
            background-color: transparent;
        }
    }

    @include m.media(v.$phone) {
        .btn-content,
        .btn-try {
            @include m.container-fluid(100%);
        }

        .box {
            padding: v-global.$bigPad;
        }
    }
}
