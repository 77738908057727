@import 'styles/variables';
@import 'styles/_colors.scss';

#ds-progress-bar {
    font-family: $primaryfont;
    display: flex;
    align-items: center;
    position: relative;

    .level-title {
        font-size: $medium;
    }

    ul {
        list-style-type: none;
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 0px;
        counter-reset: step;

        &.verification-pills {
            padding-left: 45px;
            padding-right: 100px;

            & .step {
                width: 20px;
                height: 20px;

                &.step-verification_success {
                    font-size: $smallplus;
                }

                &.step-active {
                    font-size: $tiny;
                }

                &.step-disabled {
                    font-size: $tiny;
                }
            }
        }
    }

    .step {
        background-color: rgba(197, 204, 229, 1);
        width: 32px;
        height: 32px;
        border: none;
        color: $sign-up-blue;

        &.step-active {
            background-color: $sign-up-blue !important;
            color: $white;

            .step-number::before {
                content: "circle";
            }
        }

        &.step-success {
            @extend .ds-background;
            color: $white;

            .step-number::before {
                content: "check";
            }
        }

        &.step-disabled {
            background-color: rgba(197, 204, 229, 1);
            color: $white;

            .step-number::before {
                content: "circle";
            }
        }

        &.step-verification_success {
            background-color: $second-success-green !important;
            color: $white !important;

            .step-number::before {
                content: "check";
            }
        }

        .step-number::before {
            content: counter(step);
            counter-increment: step;
        }
    }

    .ds-linear-progress {
        .mdc-linear-progress__buffer-bar {
            background-color: rgba(197, 204, 229, 1);
        }

        .mdc-linear-progress__bar-inner {
            border-color: $ds-purple;
        }
    }

    .ds-linear-progress_success {
        margin-right: 55px;

        .mdc-linear-progress__buffer-bar {
            background-color: rgba(197, 204, 229, 1);
        }

        .mdc-linear-progress__bar-inner {
            border-color: $success-green;
        }
    }

    .verification-column {
        margin-top: 40px;
        max-width: 180px;
    }

    .verification-container {
        padding-left: 25px;
        padding-right: 15px;

        ds-status-tag {
            & span {
                &:first-child {
                    font-size: 11px;
                }

                &:nth-child(2) {
                    font-size: $extra-tiny;
                }
            }
        }

        .verification-column {
            max-width: 148px;

            & span:not(#ds-verification-status-tag > div > span, :first-child) {
                line-height: 12px;
            }
        }

        .tag {
            height: 20px;
            width: max-content;
        }

        .verification-column-2 {
            max-width: 170px !important;

            span {
                max-width: 155px;
            }
        }
    }

    .ds-progress-bar-vertical {
        max-width: 300px;

        @media (max-width: $phone) {
            overflow: hidden;
        }

        ds-status-tag span {
            &:first-child {
                font-size: 11px;
            }

            &:nth-child(2) {
                font-size: $tiny;
            }
        }

        .step {
            width: 20px;
            height: 20px;

            &.step-disabled {
                font-size: $tiny;
            }

            &.step-verification_success {
                font-size: $smallplus;
            }

            &.step-active {
                font-size: $tiny;
            }
        }

        ul {
            padding-bottom: 95px;
            margin-right: 16px ;
            width: 20px;

            & > li:nth-child(2) {
                margin-bottom: 38px;
            }

            @media (max-width: $phone) {
                margin-right: 10px;
            }
        }

        .ds-linear-progress_success {
            width: 340px;
            transform: translate(10px, 10px) rotate(90deg);
            transform-origin: left;
        }

        .verified span:nth-child(2) {
            color: #3B3C51;
        }

        .verification-column {
            margin-top: 0px;

            & span:not(#ds-verification-status-tag > div > span, :first-child) {
                padding-bottom: 10px;

                &:first-child {
                    padding-top: 4px;
                }

                &:last-child {
                    padding-bottom: 4px;
                }
            }
        }

        .verification-column {
            max-width: 150px;

            @media (max-width: $phone) {
                max-width: 300px;
            }
        }

        .verification-column-2 {
            max-width: 240px !important;

            span {
                max-width: 150px;

                @media (max-width: $phone) {
                    max-width: 300px;
                }
            }
        }

        .tag {
            height: 20px;
            width: max-content;
        }
    }

    ds-page-content-area > div {
        & > p {
            margin-bottom: 9px;
        }

        & > p:last-child {
            margin-bottom: 16px;
        }
    }
}
