@import 'styles/variables';

#ds-game-selector {
    z-index: 2;
    
    .game-icon-container {
        margin-left: 13px;
        margin-right: 3px;
        width: 30px;
    }

    .game-icon {
        filter: brightness(0) saturate(100%) invert(28%) sepia(96%) saturate(7338%) hue-rotate(251deg) brightness(99%) contrast(89%);
    }

    .arrow-icon {
        border: 0;
        cursor: pointer;
        height: 13px;
        transition: 0.3s ease;
        width: 10px;
    }

    .mdc-select--activated {
        .arrow-icon {
            transform: rotate(180deg) !important;
        }
    }

    .mdc-select {
        color: $menu-purple;

        .mdc-select__icon {
            height: auto;
            max-height: 30px;
            max-width: 100%;
            width: auto;
        }
        min-width: 279px;
    }

    .mdc-list-item {
        padding-left: 0;
        padding-right: 12px;
    }

    .mdc-list-item--selected .mdc-list-item__primary-text {
        color: $ds-home !important;
        line-height: $medium;
        font-family: $primaryfont !important;
    }

    .mdc-list-item__primary-text {
        color: $ds-home !important;
        line-height: $medium;
        font-family: $primaryfont !important;
    }

    .mdc-select__anchor {
        border-radius: 4px;
    }

    .mdc-select .mdc-floating-label {
        font-size: 14px !important;
        line-height: $medium;
        font-weight: 500;
        transform: translateY(-50%) !important;
        top: 50%;
        left: 36px;
    }

    .mdc-menu {
        background: rgb(243, 245, 247) !important;
        box-shadow: none !important;
    }

    .mdc-select--filled .mdc-select__anchor {
        align-items: center !important;
    }
}
