@import 'styles/variables';

#home-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 307px;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 8%);
    border-radius: 4px;
    transition: 0.3s;
    background-color: $white;

    &:hover {
        transform: scale(1.02);
    }

    p {
        margin: 0;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .title {
            margin-top: 68px;
            margin-left: 29px;
            width: 227px;
            font-family: $primaryfont;
            font-size: $homecardhuge;
            color: $ds-home;
            letter-spacing: 1.28px;
        }

        .buy-button {
            margin-bottom: 73px;
            margin-left: 29px;
            width: 184px;
            height: 50.62px;
            border: none;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $ds-home-button;
            color: $white;
            font-size: $medium;
            cursor: pointer;
            text-decoration: none;
        }
    }

    @media (max-width: $extrascreen) {
        .left {
            .title {
                margin-top: 75px;
                margin-left: 20.78px;
                width: 209.6px;
                font-size: $largeplusplus;
            }

            .buy-button {
                margin-bottom: 75.94px;
                margin-left: 20.78px;
                width: 169.89px;
            }
        }

        .card-image {
            width: 306px;
            height: 307px;
        }
    }

    @media (max-width: $phone) {
        height: 420px;
        flex-direction: column;

        .left {
            height: 151px;
            width: 100%;

            .title {
                margin-top: 39px;
                margin-left: 31px;
                width: 160px;
                font-size: $large;
            }

            .buy-button {
                width: 134px;
                height: 41px;
                margin-bottom: 0px;
                margin-left: 31px;
                font-size: $small;
            }
        }

        .card-image {
            width: 100%;
            height: 250px;
        }
    }
}
