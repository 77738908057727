@import 'styles/variables';
@import 'styles/mixins';

.ds-category-card {
    background-color: $white;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 3px;
    height: 168px !important;
    max-width: 294px !important;
    @include flex-center(column);

    @media(max-width: $desktop) {
        max-width: 354px !important;
    }

    @media(max-width: $phone) {
        max-width: 335px !important;
    }

    h4 {
        font-size: $medium;
        max-width: 242px;
        color: $dropdownprimarybackground;
    }

    .category-card-image {
        max-width: 35px !important;
        max-height: 35px !important;
    }

    .category-card-icon {
        font-size: 35px;
        color: $ds-faq-purple;
    }
}

.ds-category-card:hover,
.ds-category-card.active {
    background-color: $ds-faq-purple;

    .category-card-icon {
        color: $white;
    }

    .category-card-image {
        filter: brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(25%) hue-rotate(13deg) brightness(105%) contrast(106%);
    }

    h4 {
        color: $white !important;
        font-weight: 500 !important;
    }
}
