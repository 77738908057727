@import 'styles/variables';

#footer-dropdown {
    .dropdown-top {
        color: $menu-purple;
        padding: 15px 0;
        border-bottom: 1px solid $grayborder;

        span {
            font-size: $regular;
            font-weight: 700;
            letter-spacing: 0.448px;
        }
    }

    .dropdown-content {
        max-height: 0;
        overflow: hidden;
        margin: 0;
        font-size: $regular;
        font-weight: 400;

        a {
            display: block;
            margin: 15px 0;
            color: rgba($menu-purple, .7);
            width: fit-content;

            &:hover {
                text-decoration: underline !important;
            }

            &.Faq {
                text-transform: uppercase;
            }

            &:last-child {
                margin-bottom: 17px;
            }

            &:first-child {
                margin-top: 17px;
            }
        }
    }

    &.active {
        .arrow-icon {
            animation: rotate .3s ease 0s 1 normal forwards;
    
            @keyframes rotate {
                100% { rotate: 180deg; }
            }
        }
    
        .dropdown-content {
            animation: showUp 1.2s ease 0s 1 normal forwards;
            border-bottom: 0px solid $grayborder;
    
            @keyframes showUp {
                100% { 
                    max-height: 1000px; 
                    border-bottom: 1px solid $grayborder;
                }
            }
        }
    }

    &.inactive {
        .arrow-icon {
            rotate: 180deg;
            animation: rotateBack .3s ease 0s 1 normal forwards;
    
            @keyframes rotateBack {
                100% { rotate: 0deg; }
            }
        }
    
        .dropdown-content {
            max-height: 1000px;
            border-bottom: 1px solid $grayborder;
            animation: hide .3s ease 0s 1 normal forwards;
    
            @keyframes hide {
                100% { 
                    max-height: 0; 
                    border-bottom: 0px solid $grayborder;
                }
            }
        }
    }
}
