@import 'styles/variables';
@import 'styles/mixins';

#ds-quantity-input {
    .extra-button {
        border-radius: 4px;
        @include square(24px);
        background: $sign-up-blue;
        border: 0;
        color: $white;
        padding: 0;
        line-height: 0;
        box-shadow: none;
    }

    .input-field-responsive {
        @media (max-width: $tablet) {
            height: 30px;
        }
    }

    .btn-primary {
        @media (max-width: $tablet) {
            margin-right: 10px;
        }

        @media (max-width: $phone) {
            margin-right: 0;
        }
    }

    .btn-secondary{
        @media (max-width: $tablet) {
            margin-left: 10px;
        }

        @media (max-width: $phone) {
            margin-left: 0;
        }
    }

    input[type='number'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input {
        overflow: unset;
        padding-right: unset;
        text-overflow: unset;
        white-space: unset;
    }
 
    .btn:disabled {
        opacity: 0.2;
    }

    .quantity-input {
        padding: 0 10px !important;

        @media (max-width: $tablet) {
            max-height: 30px;
        }
    }

    @media (max-width: $desktop) {
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }

        .quantity-input {
            max-width: 80px;
            max-height: 20px;
            min-width: 30px;
            padding: 0 !important;
        }

        .input-whole-transparent:not(.mdc-text-field--disabled) .mdc-text-field__input,
        .input-password.mdc-text-field--disabled .mdc-text-field__input,
        .input-whole-transparent.mdc-text-field--disabled .mdc-text-field__input {
            font-size: $regular;
            text-align: center;
        } 
    }

    .quantity-field:hover {
        #ds-number-arrows {
            opacity: 1;
            visibility: visible;
        }
    }
}


