@import 'styles/variables';

#google-autocomplete-places-input {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        transition: background-color 5000s ease-in-out 0s;
        font-family: $ds-home !important;
        font-size: 1rem !important;
        z-index: inherit;
    }
    .ds-input--error {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    .ds-input--warning {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    .ds-input--success {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
    
    .input-password .mdc-text-field__input {
        color: $ds-home !important;
    }

    .mdc-select__selected-text {
        color: $ds-home !important;
    }

    .mdc-floating-label {
        color: $ds-home !important;
    }

    .save-button {
        margin-left: auto;
    }

    .mdc-select--outlined {
        .mdc-select__anchor {
            height: 50px !important;
        }
    }

    .circle-icon {
        top: 16px;
        right: 16px;
        height: 16px;
        width: 16px;
    }

    .circle-autocomplete-icon {
        top: 24px;
        right: 16px;
        height: 17px;
        width: 16px;
    }

    .mdc-list-item__text, .mdc-select__selected-text {
        font-size: $medium;
    }

    .mdc-floating-label {
        font-size: 16px !important;
        top: 50%;
        transform: translateY(-50%) !important;
    }

    .mdc-text-field__input {
        padding-right: 30px;
    }

    .select-input {
        padding-right: 41px;
    }
}

.pac-container {
    background-color: $palegray;
    border-color: $sign-up-blue;
    border-radius: 4px;
    font-family: $primaryfont;
    top: unset !important;
    left: unset !important;

    .pac-item {
        border-color: $palegray;
        color: $ds-home;
        font-size: $regular;

        .pac-icon {
            filter: invert(27%) sepia(63%) saturate(7426%) hue-rotate(246deg) brightness(94%) contrast(98%);
        }

        .pac-item-query {
            color: $ds-home;
            font-size: $regular;
    
            &:hover {
                color: $white;
            }
        }

        &:hover {
            background-color: $sign-up-blue;
            color: $white !important;

            .pac-icon {
                filter: brightness(0) invert(1);
            }

            .pac-item-query {
                color: $white;
            }
        }
    }

    .pac-item-selected {
        background-color: $sign-up-blue;
        color: $white;
    }

    @media (max-width: $desktop) {
        .pac-item {
            font-size: $small;
        }

        .pac-item-query {
            font-size: $small;
        }
    }
}

.pac-logo:after {
    background-image: none;
    height: 0;
    padding: 0;
}

.mdc-list-item__primary-text {
    color: $ds-home !important;
}

.expand-icon {
    transition: 0.3s ease;
}

.mdc-select--activated {
    .expand-icon {
        transform: rotate(180deg) !important;
    }
}


