@import 'styles/variables';

#information-card {
    background-color: rgb(255, 255, 255);
    height: 309px;
    border-radius: 12px;
    padding: 58px 27px 40px 27px;

    @media (max-width: $phone) {
        padding-left: 18px;
        padding-right: 18px;
    }

    .card-icon {

        & img {
          height: 57px;
          width: 75px;
          margin-bottom: 23px;  
        }
    }

    .title-card {

        & p {
            color: $ds-home;
            line-height: 30px;

            @media (max-width: $phone) {
                font-size: $medium;
                line-height: 24px;
                margin-bottom: 9px;
            }
        }
    }

    .text-card  {
        max-width: 443px;
        color: $ds-home;
        line-height: 27px;
        margin-top: 19px;
        opacity: 0.75;

        @media (max-width: $phone) {
            font-size: $regular;
            line-height: 21px;
        }
    }
}

