@use 'styles/variables' as v;
@use 'styles/mixins' as m;

footer:has(#footer) {
    display: flex;
}

#footer {
    position: relative;
    bottom: 0;
    padding: 0;
    background-color: v.$white;
    padding: v.$regularPad 22px;
    border: 1px solid rgba(59, 60, 81, 0.102);
    margin-top: auto;

    @media (min-width: v.$tablet) {
        padding: 28px v.$bigPad v.$regularPad;
        min-height: auto;
    }

    @media (min-width: v.$desktop) {
        padding: 0;
    }

    .footer-content {
        position: relative;

        @media (min-width: v.$tablet) {
            height: 265px;
        }

        @media (min-width: v.$desktop) {
            width: 94.7%;
            max-width: 1180px;
            margin: 0 auto;
            height: 384px;
        }

        .social-media {
            margin-top: 30px;

            @media (min-width: v.$tablet) {
                position: absolute;
                width: 156px;
                margin: 0;
                top: 77px;
            }

            @media (min-width: v.$desktop) {
                position: absolute;
                top: 50px;
                right: 0;
                width: 156px;

                span {
                    margin-bottom: v.$regularPad;
                    color: v.$menu-purple;
                    font-size: v.$largeplus;
                    font-weight: 700;
                    line-height: normal;
                    width: fit-content;
                }
            }
        }

       .main-content {
            .info-section {
                .logo {
                    margin: 0 auto;
                    width: fit-content;

                    img {
                        width: 194px;
                    }
                }
        
                .contact-email {
                    margin: 15px auto 25px;
                    color: v.$menu-purple;
                    text-align: center;

                    @media (min-width: v.$tablet) {
                        text-align: left;
                    }

                    @media (min-width: v.$desktop) {
                        margin: v.$regularPad auto 0;
                    }
                }
            }

            .links-section-tablet-desktop {
                display: none;
            }

            @media (min-width: v.$tablet) {
                @include m.flex(initial, space-between);

                .links-section-mobile {
                    display: none;
                }

                .links-section-tablet-desktop {
                    display: grid;
                    grid-template-columns: repeat(3, 112px);
                    gap: 27px;
                    margin-right: 8px;
                }
            }

            @media (min-width: v.$desktop) {
                margin-top: 0;
                padding-top: 50px;
                width: 82%;
                max-width: 928px;

                .links-section-tablet-desktop {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 100px;
                    margin: 0;
                }
            }

            @media (min-width: v.$widescreen) {
                .links-section-tablet-desktop {
                    margin: 0;
                    gap: 0;
                    @include m.flex(initial, space-between);
                    min-width: 634px;
                }
            }
        }

        .trustpilot-reviews {
            margin: v.$bigPad auto 35px;
            text-align: center;

            a {
                font-size: v.$regular;
                font-weight: 500;
                color: v.$menu-purple;
                font-family: v.$primaryfont;
                opacity: .7;

                &:hover {
                    text-decoration: underline !important;
                }
            }
            
            .stars {
                width: fit-content;
                margin: 0 auto;

                span {
                    color: v.$menu-purple;
                    font-size: v.$medium;
                    font-weight: 500;
                }
            }

            @media (min-width: v.$tablet) {
                margin: 23px 0 38px;
                @include m.flex(flex-end, initial, column);

                .stars {
                    margin: 0;

                    span {
                        font-weight: 400;
                    }
                }
            }

            @media (min-width: v.$desktop) {
                margin: 0;
                position: absolute;
                right: 22px;
                min-width: 132px;
                top: 189px;
                align-items: center;

                .stars {
                    margin-top: 6px;

                    span {
                        margin-top: 3px;
                        font-weight: 500;
                    }
                }
            }
        }

        .bottom-section {
            font-size: v.$small;
            color: v.$menu-purple;
            max-width: 194px;
            margin: v.$bigPad auto 0;

            @media (min-width: v.$tablet) {
                max-width: none;
                position: absolute;
                bottom: 0;
                font-size: v.$regular;
                line-height: normal;
            }

            @media (min-width: v.$desktop) {
                padding-bottom: v.$regularPad;
            }
        }
    }
}
