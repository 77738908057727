@import 'styles/variables';

#ds-item-category-banner {
    background-color: $white;
    padding: 29px 53px 29px 53px;
    display: grid;
    min-height: 206px;
    grid-template-columns: repeat(3, 1fr);

    .image-container {
        background-color: rgba(84, 59, 239, 0.2);
        padding: 5px;
        border-radius: 5px;
        width: 50px;
        height: 50px;

        img {
            width: 40px;
            height: 40px;
            margin: auto;
            filter: invert(54%) sepia(95%) saturate(7491%) hue-rotate(245deg) brightness(94%) contrast(100%);
        }

        .armor-margin {
            margin: 3px;
        }
    }

    .text-large-plus {
        p {
            font-family: $primaryfont;
            color: $ds-home;
        }
    }

    @media (max-width: $desktop) {
        padding: 30px;
        grid-column-gap: 30px;
        padding-bottom: 18px;
        min-height: 289px;

        .text-large-plus {
            font-size: $medium !important;
        }
    }

    @media (max-width: $phone) {
        padding: 25px;
        grid-template-columns: auto;
        grid-row-gap: 25px;
        min-height: 706px;
    }

    > div {
        display: grid;
        grid-template-columns: 50px auto;
        grid-column-gap: 24px;

        p {
            margin: 0px !important;
            max-width: 240px;
            line-height: 32px;
            display: inline-block;
        }

        @media (max-width: $tablet) {
            grid-template-columns: auto;
            grid-template-rows: 60px auto;

            p {
                max-width: 137px;

                @media (max-width: $phone) {
                    max-width: 191px;
                }
            }
        }

        @media (max-width: $phone) {
            justify-items: center;
            text-align: center;
        }
    }

    img {
        max-width: 50px;
        max-height: 50px;
    }
}
