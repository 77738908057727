@import 'styles/variables';

#ds-dialog {
    .input-password {
        width: 294px;
    }

    .dialog-icon {
        width: 67px;
        height: 67px;
        background-color: $off-red;
        color: $red;
        border-radius: 100%;
        font-size: 42px;
        margin-bottom: 10px;
    }

    .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $ds-home-button;
    }

    .dialog-content {
        line-height: 32px;
        font-size: $medium;
    }

    .dialog-title {
        font-size: $extralarge;
    }

    .otp-container {
        width: 350px;

        @media (max-width: $phone) {
            width: 310px;
        }
    }

    .circle-icon {
        height: 16px;
        position: absolute;
        right: 194px;
        top: 16px;
        width: 16px;
    }

    .cancel-btn {
        height: 50px !important;
        width: 130px !important;

        &.danger {
            background-color: $off-red !important;
            color: $red !important;
        }

        &.primary {
            background-color: $ds-home-button !important;
            color: $white !important;
        }

        &.no-bg {
            background-color: transparent !important;
        }
    }

    .mini-spinner-icon {
        position: absolute;
        right: 190px;
        top: 12px;
    }

    .mdc-dialog__surface {
        min-width: 657px !important;
        min-height: 409px !important;
    }

    .mdc-dialog .mdc-dialog__content {
        padding: 0 !important;
    }

    @media (max-width: $tablet) {
        .mdc-dialog__surface {
            min-width: 353px !important;
            min-height: 391px !important;
        }

        .dialog-content {
            font-size: $regular;
        }

        .dialog-title {
            font-size: $large;
        }

        .circle-icon {
            right: 157px;
            top: 16px;
        }

        .mini-spinner-icon {
            right: 153px;
            top: 12px;
        }
    }

    @media (max-width: $phone) {
        .mdc-dialog__surface {
            min-width: 353px !important;
            min-height: 439px !important;
        }

        .dialog-inner {
            padding: 0px 5px;
            line-height: 24px;
        }

        .circle-icon {
            right: 40px;
            top: 16px;
        }

        .mini-spinner-icon {
            right: 36px;
            top: 12px;
        }

        .cancel-btn {
            width: 138px !important;
        }
    }
}
