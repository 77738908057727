@import 'styles/variables';

#ds-filter {
    mdc-select {
        .mdc-text-field--outlined {
            padding-left: 3%;
            padding-right: 3%;
            max-height: 27px;
        }

        .mdc-notched-outline__notch {
            width: auto !important;
        }

        .mdc-select__anchor {
            mdc-notched-outline mdc-floating-label,
            .mdc-select__selected-text-container .mdc-select__selected-text {
                font-size: $regular;
            }
        }

        .input-password {
            .mdc-text-field__input,
            .mdc-text-field__affix {
                font-size: $regular;
            }

            .mdc-text-field__affix {
                padding-left: 0;
            }
        }

        .mdc-floating-label--required:after {
            color: $red;
        }
    }

    .end-price-input input {
        max-width: 40px;
    }

    .dropdown-menu {
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        display: block;
        transform: scale(0.9);
        transition: opacity .03s linear;
    }

    .dropdown-menu.show {
        opacity: 1;
        visibility: visible;
        transform: scale(1) !important;
        transform-origin: top center;
        transition: opacity .03s linear, visibility .03s linear, transform .12s cubic-bezier(0, 0, .2, 1);
        will-change: transform, opacity, visibility;
    }

    .mdc-slider {
        margin: -10px 5px;
    }

    .mdc-slider .mdc-slider__thumb-knob {
        max-width: 14px;
        max-height: 14px;
        border: 7px solid $blue;
    }

    .dynamic-filter .mdc-select__anchor {
        border-radius: 4px;
    }

    .price-select-container {
        background: $slateblue-off-low-opacity;
        border: 0;
        height: 50px;
        cursor: pointer;
        outline: none;

        .price-title {
            top: 55%;
            left: 46px;
            right: auto;
            font-size: 12px !important;
        }

        .faq-arrow-icon {
            transform: rotate(0deg);
            transition: all .3s ease-in-out;
        }

        &.show .faq-arrow-icon {
            transform: rotate(180deg);
        }
    }

    .price-select-container:hover {
        background: rgba(76, 96, 133, .3);
    }

    .dropdown-arrow {
        position: absolute;
        right: 10px;
        margin-top: 5px;
        font-size: 22px;
        
        img {
            width: 10px;
            height: 10px;
        }
    }

    .dropdown-arrow-up {
        opacity: 0;
        transition: opacity 75ms linear;
    }

    .dropdown-arrow-down {
        opacity: 1;
        transition: opacity 75ms linear 75ms;
    }

    .show {
        .dropdown-arrow-down {
            opacity: 0;
            transition: opacity 49.5ms linear;
        }

        .dropdown-arrow-up {
            opacity: 1;
            transition: opacity .1005s linear 49.5ms;
        }
    }

    .price-selector-floating-container {
        width: 100%;
        background: $secondarybackground;
        border: 0;
        border-radius: 4px;
        top: 47px;
        min-height: 82px;
        box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
        z-index: 1;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            padding-right: 0;
            -moz-appearance: textfield;
        }

        .mdc-text-field--outlined {
            padding-left: 8px;
            padding-right: 8px;
        }

        .mdc-text-field__affix--suffix {
            padding-left: 0;
            padding-right: 28px;
        }
    }

    .price-label {
        padding-left: 1px;
        margin-top: 16px;
    }

    .price-input {
        height: 27px;
    }
}
