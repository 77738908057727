@import 'styles/variables';

#ds-id-selfie-verification-progress {
    width: fit-content;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    
    @media (max-width: $phone) {
        grid-auto-flow: row;
        row-gap: 15px;
    }

    .verification-step {
        display: flex;
        align-items: center;
        font-weight: bold;
    }

    .number {
        height: 27px;
        width: 27px;
        display: grid;
        align-items: center;
        justify-items: center;
        background-color: $lightpurple;
        color: $white;
        font-weight: bold;
        border-radius: 50%;
    }

    .line {
        background-color: $lightpurple;
        height: 2px;
        width: 23px;
    }

    .circle-icon{
        height: 27px;
        width: 27px;
    }
}
