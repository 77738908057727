@import 'styles/variables';

#ds-template-paragraph-image {

    .template-pragraph-image-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        
        @media (max-width: $phone) {
            grid-template-columns: auto;
            justify-items: center;
            row-gap: 40px;
        }
    }
    
    .grid-justify-content-end {
        justify-items: end;
    }
    
    .first {
        order: 1;
    }
    
    .second {
        order: 2;
    }
    
    @media (max-width: $phone) {
        .grid-justify-content-end {
            justify-items: center;
        }
        
        .first {
            order: unset;
        }
        .second {
            order: unset;
        }
    }

    .area-img img {
        max-width: 450px;
        max-height: 450px;

        @media (max-width: $tablet) {
            max-width: 400px;
            max-height: 400px;
        }
        
        @media (max-width: $phone) {
            max-width: 335px;
            max-height: 335px;
        }
    }
}
