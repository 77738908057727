@import 'styles/variables';
@import 'styles/mixins';

#networks {
    max-width: 156px;
    margin: 0 auto;
    
    .network {
        background-color: $sign-up-blue;
        @include flex-center();
        @include square(30px);
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
    }

    a:hover .network {
        background-color: rgba(110, 86, 255, 1);
    }

    a:active .network {
        background-color: $sign-up-blue;
    }
}
