@import 'styles/variables';

#ds-footer-section {
    color: $menu-purple;
    text-align: left;
    font-size: $regular;
    width: fit-content;
    
    span {
        font-weight: 700;
        display: block;
        line-height: 19px;
        width: fit-content;
        font-size: $medium;
        letter-spacing: 0.512px;

        @media ($desktop <= width) {
            font-size: $largeplus;
            line-height: normal;
            letter-spacing: 0.576px;
        }
    }

    .links {
        margin-top: 17px;

        @media (min-width: $desktop) {
            margin-top: 20px;
        }

        a {
            display: block;
            color: $menu-purple;
            font-weight: 300;
            margin-bottom: 12px;
            width: fit-content;
            line-height: 18px;
            letter-spacing: 0.1px;

            &.Faq {
                text-transform: uppercase;
            }

            &:hover {
                text-decoration: underline !important;
            }

            @media (min-width: $desktop) {
                font-size: $regular;
                margin-bottom: 15px;
                opacity: .8;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
