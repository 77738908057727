@import "styles/variables";
@import "styles/mixins";

#ds-join-banner {
    max-width: 1260px;

    @media (max-width: $phone) {
        max-width: 335px !important;
    }

    .image-container {
        margin-top: 51px;
        height: 346px;
        padding: 40px;
    }

    .medium-image {
        display: none;
    }

    .image-container-alt {
        margin-top: 6rem;
        height: 346px;
    }

    .character-6 {
        bottom: 28px;
        right: 6rem;
    }

    .paragraph-text {
        width: 80%;
        margin-bottom: 60px !important;

        @media (max-width: $desktop) {
            width: unset;
            margin-bottom: 73px !important;
        }

        @media (max-width: $phone) {
            margin-bottom: 20px !important;
        }
    }

    button {
        border: none !important;
        border-radius: 4px !important;
        font-family: $roboto;
    }
}

@media (min-width: $tablet) and (max-width: $tablet) {
    .padding-container {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}

@media (max-width: $desktop) {
    #ds-join-banner {
        .image-container {
            margin-top: 0 !important;
            height: auto;
            padding: 0 !important;

            > .row {
                @include full-size();
                margin: 0 !important;

                .paragraph-text {
                    padding: 0 !important;
                    font-size: 14px;
                }
            }
        }

        .image-container-alt {
            padding: 0 !important;
            margin-top: 3rem !important;
            height: auto;
        }

        .image-mobile {
            display: flex !important;
            @include flex(flex-end, flex-end);
            padding: 0 !important;
        }

        .button-mobile {
            width: 90%;
            max-width: 214px !important;
        }

        .character-6 {
            position: unset !important;
            width: 250px;
        }
    }
}

@media (min-width: $phone) and (max-width: $desktop) {
    #ds-join-banner {
        .title-text {
            padding-top: 0 !important;
        }

        .mb-4 {
            margin-bottom: 10px !important;
        }

        .standard-image {
            display: none;
        }

        .medium-image {
            display: block;
        }

        .image-container {
            margin-top: 1rem;

            > row {
                flex-wrap: nowrap;
            }

            .padding-container {
                padding: 30px 0 30px 30px !important;

                div {
                    padding: 0 !important;
                }
            }
        }

        .image-mobile {
            overflow: hidden;
        }

        .image-container-alt {
            margin-top: 5rem;
        }

        .character-6 {
            right: -130px;
        }

        .character-6 {
            position: absolute !important;
            right: 0;
            top: 4px;
            width: 271.5px;
            max-width: 100%;
            object-fit: contain;
        }
    }
}

@media (min-width: ($desktop + 1px)) and (max-width: $widescreen) {
    #ds-join-banner {
        .character-6 {
            right: 3rem !important;
            bottom: 13px;
        }
    }
}

@media (min-width: $tablet) {
    .padding-container {
        padding-top: 5px;
        padding-bottom: 3rem;
    }
}

@media (max-width: $phone) {
    #ds-join-banner {
        .paragraph-text {
            margin-bottom: 20px !important;
        }

        .image-mobile {
            align-items: center;
            justify-content: center;
        }
    }
}

@media (max-width: $phone) {
    #ds-join-banner {
        margin-top: 40px;
        .image-container {
            @include padding-x(20px, false, true);
        }

        .padding-container {
            @include padding-x(12px, false, true);
            padding-top: 24px;
        }
    }
}

@media (min-width: $phone) and (max-width: $tablet) {
    #ds-join-banner {
        margin-top: 60px;
    }
}
