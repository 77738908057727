@import 'styles/variables';

#ds-newsletter-prompt {
    display: none;

    .banner-box {
        background-color: $slategrey;
        min-height: 50px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 20px auto auto;
        align-items: center;
        padding: 10px 30px;

        img {
            width: 20px;
            height: 20px;
        }

        >span {
            padding-left: 10px;
        }

        .text-large.cursor-pointer {
            justify-self: end;
        }
    }
}
