@import 'styles/variables';

#ds-template-paragraph {
    color: $ds-home;
    .smaller-paragraph {
        display: inline-block;
        max-width: 803px;
    }

    p:first-child {
        margin: 0;
    }

    .divisor-title {
        width: 90px;
        height: 5px;
        margin-bottom: -20px;
        margin-top: 14px;
    }

    .title {
        p {
            font-family: $primaryfont;
        }
    }

    .displayFairFont {
        line-height: 2;

        p {
            line-height: 2;
        }
    }

    .ds-mb {
        margin-bottom: 20px;
    }

    .text-medium {
        @media (max-width: $tablet) {
            font-size: $regular;
        }
    }
}
