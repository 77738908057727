@import 'styles/variables';

#ds-number-arrows {
    top: 12.4px;
    right: 10px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.15s linear, opacity 0.15s linear;

    &.suffix {
        top: 17.4px !important;
        right: 10px !important;
    }

    .first-arrow {
        transform: rotate(180deg);
    }

    img {
        width: 9px;
        height: 9px;
    }

    img:first-child {
        margin-bottom: -1px;
    }

    img:last-child {
        margin-top: -1px;
    }
}
