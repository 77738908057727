@import 'styles/variables';
@import 'styles/mixins';

:root {  
    --size: 26px;
    --color: $sign-up-blue;
}

ds-dynamic-icon, 
#ds-dynamic-icon .dynamic-icon {
    @include optionIcon(var(--size), var(--color));
}
